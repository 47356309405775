import { SET_MEET_LIST, SET_MEET_LIST_LOADING } from "../../types";

const INITIAL_STATE = {
  meetList: [],
  meetListLoading: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_MEET_LIST:
      return {
        ...state,
        meetList: {
          ...state.meetList,
          data: action.payload.data,
          meta: {
            ...state.meetList.meta,
            pagination: action.payload.meta.pagination,
            params: { ...state.meetList.params, ...action.payload.meta.params }
          }
        }
      }
    case SET_MEET_LIST_LOADING:
      return { ...state, loading: action.payload }
    default:
      return state;
  }
}