import {
  SET_ANSWER_LIST_LOADING,
  SET_ANSWER_LIST,
  SET_ANSWER,
} from '../types';

const INITIAL_STATE = {
  answerList: {
    data: [],
    meta: {
      pagination: {
        current_page: 1,
        per_page: 30,
        total_pages: 0,
        total: 0,
      },
      params: {
        page: 1,
        per_page: 30,
        sort_by: 'id',
        sort_order: 'ASC',
        search: null,
      },
    },
  },
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ANSWER_LIST:
      return {
        ...state,
        answerList: {
          ...state.answerList,
          data: action.payload.data,
          meta: {
            ...state.answerList.meta,
            pagination: action.payload.meta.pagination,
            params: { ...state.answerList.meta.params, ...action.payload.meta.params },
          },
        },
      };
    case SET_ANSWER_LIST_LOADING:
      return { ...state, loading: action.payload };
    case SET_ANSWER:
      return {
        ...state,
        answerList: {
          ...state.answerList,
          data: state.answerList.data.map((answer) => {
            if (answer.id === action.payload.id) {
              return action.payload;
            }
            return answer;
          }),
        },
      };
    default:
      return state;
  }
};
