import {
  SET_SESSION_LIST_LOADING,
  SET_SESSION_LIST,
} from '../types';

const INITIAL_STATE = {
  sessionList: {
    data: [],
    meta: {
      pagination: {
        current_page: 1,
        per_page: 30,
        total_pages: 0,
        total: 0,
      },
      params: {
        page: 1,
        per_page: 30,
        sort_by: 'id',
        sort_order: 'ASC',
        search: null,
      },
    },
  },
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SESSION_LIST:
      return {
        ...state,
        sessionList: {
          ...state.sessionList,
          data: action.payload.data,
          meta: {
            ...state.sessionList.meta,
            pagination: action.payload.meta.pagination,
            params: { ...state.sessionList.meta.params, ...action.payload.meta.params },
          },
        },
      };
    case SET_SESSION_LIST_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
