
import { SET_CURRENT_BUTTON_NAME } from '../../types';

const INITIAL_STATE = {
  buttonValue: 'button1'
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_BUTTON_NAME: 
      return { ...state, buttonValue:action.payload }
      break;
    default:
      return state;
  }
}