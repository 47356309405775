import {
  SET_SURVEY_LIST_LOADING,
  SET_SURVEY_LIST,
  SET_SURVEY_NEW_LIST,
  SET_SURVEY_NEW_LIST_LOADING,
  SET_SURVEY_NOW_LIST,
  SET_SURVEY_NOW_LIST_LOADING,
  SET_SURVEY_DONE_LIST,
  SET_SURVEY_DONE_LIST_LOADING,
  SET_SURVEY_STARTED_LIST,
  SET_SURVEY_STARTED_LIST_LOADING,
  SET_SURVEY_PARTICIPANT1_LIST_LOADING,
  SET_SURVEY_PARTICIPANT1_LIST,
  SET_SURVEY_PARTICIPANT2_LIST_LOADING,
  SET_SURVEY_PARTICIPANT2_LIST,
} from '../../types';

const INITIAL_STATE = {
  surveyList: {
    data: [],
    meta: {
      pagination: {
        current_page: 1,
        per_page: 30,
        total_pages: 0,
        total: 0,
      },
      params: {
        page: 1,
        per_page: 30,
        sort_by: 'id',
        sort_order: 'ASC',
        search: null,
      },
    },
  },
  loading: false,
  surveyNewList: { data: [] },
  surveyNewListLoading: false,
  surveyNowList: { data: [] },
  surveyNowListLoading: false,
  surveyDoneList: { data: [] },
  surveyDoneListLoading: false,
  surveyStartedList: { data: [] },
  surveyStartedListLoading: false,
  surveyParticipant1List: { data: [] },
  surveyParticipant1ListLoading: false,
  surveyParticipant2List: { data: [] },
  surveyParticipant2ListLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SURVEY_LIST:
      return {
        ...state,
        surveyList: {
          ...state.surveyList,
          data: action.payload.data,
          meta: {
            ...state.surveyList.meta,
            pagination: action.payload.meta.pagination,
            params: { ...state.surveyList.meta.params, ...action.payload.meta.params },
          },
        },
      };
    case SET_SURVEY_LIST_LOADING:
      return { ...state, loading: action.payload };
    case SET_SURVEY_NEW_LIST:
      return { ...state, surveyNewList: action.payload };
    case SET_SURVEY_NEW_LIST_LOADING:
      return { ...state, surveyNewListLoading: action.payload };
    case SET_SURVEY_NOW_LIST:
      return { ...state, surveyNowList: action.payload };
    case SET_SURVEY_NOW_LIST_LOADING:
      return { ...state, surveyNowListLoading: action.payload };
    case SET_SURVEY_DONE_LIST:
      return { ...state, surveyDoneList: action.payload };
    case SET_SURVEY_DONE_LIST_LOADING:
      return { ...state, surveyDoneListLoading: action.payload };
    case SET_SURVEY_STARTED_LIST:
      return { ...state, surveyStartedList: action.payload };
    case SET_SURVEY_STARTED_LIST_LOADING:
      return { ...state, surveyStartedListLoading: action.payload };
    case SET_SURVEY_PARTICIPANT1_LIST:
      return { ...state, surveyParticipant1List: action.payload };
    case SET_SURVEY_PARTICIPANT1_LIST_LOADING:
      return { ...state, surveyParticipant1ListLoading: action.payload };
    case SET_SURVEY_PARTICIPANT2_LIST:
      return { ...state, surveyParticipant2List: action.payload };
    case SET_SURVEY_PARTICIPANT2_LIST_LOADING:
      return { ...state, surveyParticipant2ListLoading: action.payload };
    default:
      return state;
  }
};
