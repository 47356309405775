import {
  SET_DASHBOARD_USER_COUNT,
  SET_DASHBOARD_USER_COUNT_LOADING,
  SET_DASHBOARD_SURVEY_TIME_STATUS_LOADING,
  SET_DASHBOARD_SURVEY_TIME_STATUS,
  SET_DASHBOARD_SURVEY_SOLVED_STATUS_PIE_CHART,
  SET_DASHBOARD_SURVEY_SOLVED_STATUS_PIE_CHART_LOADING,
} from '../types';

const INITIAL_STATE = {
  userCount: {},
  userCountLoading: false,
  surveyTimeStatus: {},
  surveyTimeStatusLoading: false,
  surveySolvedStatusPieChart: {},
  surveySolvedStatusPieChartLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DASHBOARD_USER_COUNT:
      return { ...state, userCount: action.payload };
    case SET_DASHBOARD_USER_COUNT_LOADING:
      return { ...state, userCountLoading: action.payload };
    case SET_DASHBOARD_SURVEY_TIME_STATUS:
      return { ...state, surveyTimeStatus: action.payload };
    case SET_DASHBOARD_SURVEY_TIME_STATUS_LOADING:
      return { ...state, surveyTimeStatusLoading: action.payload };
    case SET_DASHBOARD_SURVEY_SOLVED_STATUS_PIE_CHART:
      return { ...state, surveySolvedStatusPieChart: action.payload };
    case SET_DASHBOARD_SURVEY_SOLVED_STATUS_PIE_CHART_LOADING:
      return { ...state, surveySolvedStatusPieChartLoading: action.payload };
    default:
      return state;
  }
};
