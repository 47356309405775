// ** Reducers Imports
import layout from './layout';
import navbar from './navbar';

import userProfile from './reducers/user/profileReducer';
import survey from './reducers/survey/surveyReducer';
import surveyTag from './reducers/survey/tagReducer';
import tag from './reducers/tagReducer';
import question from './reducers/question/questionReducer';
import questionValidationType from './reducers/question/questionValidationTypeReducer';
import dashboard from './reducers/dashboardReducer';
import session from './reducers/sessionReducer';
import answer from './reducers/answerReducer';
import modalVisible from './reducers/other/modalReducer';
import changeCurrentButtonFromRedux from './reducers/other/listButtonReducer';
import meet from './reducers/meet/meetReducer'
import answerHelper from './reducers/other/answerHelperReducer'

const rootReducer = {
  navbar, layout, userProfile, survey, surveyTag, tag, question, questionValidationType, dashboard, session, answer, modalVisible,changeCurrentButtonFromRedux,meet,answerHelper
};

export default rootReducer;
