import {
  SET_TAG_SCHOOL_CHAIN,
  SET_TAG_SCHOOL,
  SET_TAG_GRADE,
  SET_TAG_SCHOOL_ROOM,
  SET_TAG_USER_ROLE,
} from '../types';

const INITIAL_STATE = {
  schoolChains: [],
  schools: [],
  schoolRooms: [],
  grades: [],
  userRoles: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_TAG_SCHOOL_CHAIN:
      return { ...state, schoolChains: action.payload };
    case SET_TAG_SCHOOL:
      return { ...state, schools: action.payload };
    case SET_TAG_GRADE:
      return { ...state, grades: action.payload };
    case SET_TAG_SCHOOL_ROOM:
      return { ...state, schoolRooms: action.payload };
    case SET_TAG_USER_ROLE:
      return { ...state, userRoles: action.payload };
    default:
      return state;
  }
};
