export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const SET_USER_PROFILE = 'SET_USER_PROFILE';

export const SET_SURVEY_LIST = 'SET_SURVEY_LIST';
export const SET_SURVEY_LIST_LOADING = 'SET_SURVEY_LIST_LOADING';
export const SET_SURVEY_NEW_LIST = 'SET_SURVEY_NEW_LIST';
export const SET_SURVEY_NEW_LIST_LOADING = 'SET_SURVEY_LIST_NEW_LOADING';
export const SET_SURVEY_DONE_LIST = 'SET_SURVEY_DONE_LIST';
export const SET_SURVEY_DONE_LIST_LOADING = 'SET_SURVEY_DONE_LIST_LOADING';
export const SET_SURVEY_NOW_LIST = 'SET_SURVEY_NOW_LIST';
export const SET_SURVEY_NOW_LIST_LOADING = 'SET_SURVEY_NOW_LIST_LOADING';
export const SET_SURVEY_STARTED_LIST_LOADING = 'SET_SURVEY_STARTED_LIST_LOADING';
export const SET_SURVEY_STARTED_LIST = 'SET_SURVEY_STARTED_LIST';
export const SET_SURVEY_TAG = 'SET_SURVEY_TAG';
export const SET_SURVEY_PARTICIPANT1_LIST_LOADING = 'SET_SURVEY_PARTICIPANT1_LIST_LOADING';
export const SET_SURVEY_PARTICIPANT1_LIST = 'SET_SURVEY_PARTICIPANT1_LIST';
export const SET_SURVEY_PARTICIPANT2_LIST_LOADING = 'SET_SURVEY_PARTICIPANT2_LIST_LOADING';
export const SET_SURVEY_PARTICIPANT2_LIST = 'SET_SURVEY_PARTICIPANT2_LIST';

export const SET_TAG_SCHOOL_CHAIN = 'SET_TAG_SCHOOL_CHAIN';
export const SET_TAG_SCHOOL = 'SET_TAG_SCHOOL';
export const SET_TAG_GRADE = 'SET_TAG_GRADE';
export const SET_TAG_SCHOOL_ROOM = 'SET_TAG_SCHOOL_ROOM';
export const SET_TAG_USER_ROLE = 'SET_TAG_USER_ROLE';

export const SET_QUESTION_LIST = 'SET_QUESTION_LIST';
export const RESET_QUESTION = 'RESET_QUESTION';
export const SET_QUESTION = 'SET_QUESTION';
export const DEL_QUESTION = 'DEL_QUESTION';
export const ADD_QUESTION = 'ADD_QUESTION';
export const SET_QUESTION_LIST_LOADING = 'SET_QUESTION_LIST_LOADING';
export const SET_QUESTION_VALIDATION_TYPE_LIST = 'SET_QUESTION_VALIDATION_TYPE_LIST';
export const SET_QUESTION_VALIDATION_TYPE_LIST_LOADING = 'SET_QUESTION_VALIDATION_TYPE_LIST_LOADING';
export const SET_ACTIVE_QUESTION = 'SET_ACTIVE_QUESTION';

export const SET_MEET_LIST = 'SET_MEET_LIST';
export const SET_MEET_LIST_LOADING = 'SET_MEET_LIST_LOADING';

export const SET_DASHBOARD_USER_COUNT = 'SET_DASHBOARD_USER_COUNT';
export const SET_DASHBOARD_USER_COUNT_LOADING = 'SET_DASHBOARD_USER_COUNT_LOADING';
export const SET_DASHBOARD_SURVEY_TIME_STATUS = 'SET_DASHBOARD_SURVEY_TIME_STATUS';
export const SET_DASHBOARD_SURVEY_TIME_STATUS_LOADING = 'SET_DASHBOARD_SURVEY_TIME_STATUS_LOADING';
export const SET_DASHBOARD_SURVEY_SOLVED_STATUS_PIE_CHART = 'SET_DASHBOARD_SURVEY_SOLVED_STATUS_PIE_CHART';
export const SET_DASHBOARD_SURVEY_SOLVED_STATUS_PIE_CHART_LOADING = 'SET_DASHBOARD_SURVEY_SOLVED_STATUS_PIE_CHART_LOADING';

export const SET_SESSION_LIST = 'SET_SESSION_LIST';
export const SET_SESSION_LIST_LOADING = 'SET_SESSION_LIST_LOADING';

export const SET_ANSWER_LIST = 'SET_ANSWER_LIST';
export const SET_ANSWER_LIST_LOADING = 'SET_ANSWER_LIST_LOADING';
export const SET_ANSWER = 'SET_ANSWER';

export const SET_MODAL_VISIBLE = 'SET_MODAL_VISIBLE'
export const SET_IS_CAN_OPEN = 'SET_IS_CAN_OPEN'

export const SET_CURRENT_BUTTON_NAME = 'SET_CURRENT_BUTTON_NAME'

export const SET_ANSWER_HELPER = 'SET_ANSWER_HELPER'