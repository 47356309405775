import { SET_QUESTION_VALIDATION_TYPE_LIST, SET_QUESTION_VALIDATION_TYPE_LIST_LOADING } from '../../types';

const INITIAL_STATE = {
  data: [
    {
      id: 1,
      slug: 'VAL_NUMBER',
      type_id: 1,
      sub_types: [
        {
          id: 1,
          slug: 'VAL_GREATER_THAN',
        },
        {
          id: 2,
          slug: 'VAL_GREATER_THAN_OR_EQUAL_TO',
        },
        {
          id: 3,
          slug: 'VAL_LESS_THAN',
        },
        {
          id: 4,
          slug: 'VAL_LESS_THAN_OR_EQUAL_TO',
        },
        {
          id: 5,
          slug: 'VAL_EQUAL_TO',
        },
        {
          id: 6,
          slug: 'VAL_NOT_EQUAL_TO',
        },
        {
          id: 7,
          slug: 'VAL_BETWEEN',
        },
        {
          id: 8,
          slug: 'VAL_NOT_BETWEEN',
        },
        {
          id: 9,
          slug: 'VAL_IS_NUMBER',
        },
        {
          id: 10,
          slug: 'VAL_WHOLE_NUMBER',
        },
      ],
    },
    {
      id: 2,
      slug: 'VAL_TEXT',
      type_id: 1,
      sub_types: [
        {
          id: 11,
          slug: 'VAL_DOESNT_CONTAIN',
        },
        {
          id: 12,
          slug: 'VAL_EMAIL',
        },
        {
          id: 13,
          slug: 'VAL_URL',
        },
      ],
    },
    {
      id: 3,
      slug: 'VAL_LENGTH',
      type_id: 1,
      sub_types: [
        {
          id: 14,
          slug: 'VAL_MAX_CHAR_COUNT',
        },
        {
          id: 15,
          slug: 'VAL_MIN_CHAR_COUNT',
        },
      ],
    },
    {
      id: 3,
      slug: 'VAL_LENGTH',
      type_id: 2,
      sub_types: [
        {
          id: 14,
          slug: 'VAL_MAX_CHAR_COUNT',
        },
        {
          id: 15,
          slug: 'VAL_MIN_CHAR_COUNT',
        },
      ],
    },
  ],
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_QUESTION_VALIDATION_TYPE_LIST:
      return { ...state, data: action.payload };
    case SET_QUESTION_VALIDATION_TYPE_LIST_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
