
import { SET_ANSWER_HELPER } from '../../types';

const INITIAL_STATE = {
  answerValue: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ANSWER_HELPER: 
      return { ...state, answerValue:action.payload }
      break;
    default:
      return state;
  }
}