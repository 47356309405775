import ReactDOM from 'react-dom';
import React, { Suspense, lazy } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import { store } from './redux/store';

import { ThemeContext } from './utility/context/ThemeColors';
import themeConfig from './configs/themeConfig';
import './configs/i18n';
import Spinner from './@core/components/spinner/Fallback-spinner';

import './@core/components/ripple-button';

import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-jsx.min';

import 'react-perfect-scrollbar/dist/css/styles.css';

import '@styles/react/libs/react-hot-toasts/react-hot-toasts.scss';

import './@core/assets/fonts/feather/iconfont.css';
import './@core/scss/core.scss';
import './assets/scss/style.scss';

import '@styles/react/libs/flatpickr/flatpickr.scss';

import * as serviceWorker from './serviceWorker';

const LazyApp = lazy(() => import('./App'));
ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <ThemeContext>
          <LazyApp />
          <Toaster position={themeConfig.layout.toastPosition} toastOptions={{ className: 'react-hot-toast' }} />
        </ThemeContext>
      </Suspense>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
