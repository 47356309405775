import {
  SET_QUESTION_LIST_LOADING,
  SET_QUESTION_LIST,
  SET_QUESTION,
  SET_ACTIVE_QUESTION,
  DEL_QUESTION,
  ADD_QUESTION,
  RESET_QUESTION,
} from '../../types';

const INITIAL_STATE = {
  questionList: {
    data: [],
    meta: {
      pagination: {
        current_page: 1,
        per_page: 30,
        total_pages: 0,
        total: 0,
      },
      params: {
        page: 1,
        per_page: 30,
        sort_by: 'id',
        sort_order: 'ASC',
      },
    },
  },
  loading: false,
  activeQuestionId: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_QUESTION:
      return INITIAL_STATE;
    case SET_QUESTION_LIST:
      return {
        ...state,
        questionList: {
          ...state.questionList,
          data: action.payload.data,
          meta: {
            ...state.questionList.meta,
            pagination: action.payload.meta.pagination,
            params: { ...state.questionList.meta.params, ...action.payload.meta.params },
          },
        },
      };
    case SET_QUESTION_LIST_LOADING:
      return { ...state, loading: action.payload };
    case DEL_QUESTION:
      return {
        ...state,
        questionList: {
          ...state.questionList,
          data: state.questionList.data.filter(({ id }) => id !== action.payload),
        },
      };
    case ADD_QUESTION:
      return {
        ...state,
        questionList: {
          ...state.questionList,
          data: state.questionList.data.concat(action.payload),
        },
      };
    case SET_QUESTION:
      return {
        ...state,
        questionList: {
          ...state.questionList,
          data: state.questionList.data.map((question) => {
            if (question.id === action.payload.id) {
              return action.payload;
            }
            return question;
          }),
        },
      };
    case SET_ACTIVE_QUESTION:
      return { ...state, activeQuestionId: action.payload };
    default:
      return state;
  }
};
