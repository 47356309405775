
import { SET_MODAL_VISIBLE, SET_IS_CAN_OPEN } from '../../types';

const INITIAL_STATE = {
  modalVisible: false,
  isCanOpen: false,
  func: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_MODAL_VISIBLE:
      if (action.payload.modalVisible) {
        if (state.isCanOpen) {
          return { ...state, modalVisible: action.payload.modalVisible, func: action.payload.func };
        } else {

          action.payload.func()

        }
        return state;
      }
      if (state.func && action.payload.isRunFunction) {
        state.func()
        return { ...state, modalVisible: false, isCanOpen: false, func: null }
      }
      return { ...state, modalVisible: false, func: null }
      break;
    case SET_IS_CAN_OPEN:
      return { ...state, isCanOpen: action.payload };
      break;
    default:
      return state;
  }
}